@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  font-family: "Rubik", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.navul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.navul .navli {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  color: #cdc2dc;
}
@media screen and (max-width: 1440px) {
  .navul .navli {
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  #whyus {
    padding: 20px !important;
  }
  .whyuslist {
    font-size: 18px !important;
  }
  #pricing {
    padding: 50px 10px !important;
  }
}
@-webkit-keyframes custom-bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes custom-bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

#zio {
  -webkit-animation: custom-bounce 3s infinite ease-in-out;
  -o-animation: custom-bounce 3s infinite ease-in-out;
  -ms-animation: custom-bounce 3s infinite ease-in-out;
  -moz-animation: custom-bounce 3s infinite ease-in-out;
  animation: custom-bounce 3s infinite ease-in-out;
}
.main_header {
  font-size: 60px;
  color: white;
  font-weight: 500;
}

.Marquee {
  background: #f3f2ff;
  width: 100% !important;
  display: flex;
  box-sizing: border-box;
  color: white;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.Marquee-inner-image {
  width: 50%;
  height: 100%;
}
.Marquee-inner-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 12px;
}

.Marquee-content {
  display: flex;
  animation: marquee 20s linear infinite running;
}
.Marquee-secondary-image {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Marquee-secondary-image img {
  padding: 10px;
}

.Marquee-tag {
  width: 500px;
  height: 500px;
  padding: 1em;
  background: rgba(255, 255, 255, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.Partner-Marquee {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 50px 0px;
}

.Partner-Marquee-track {
  display: flex;
  justify-content: space-around;
  width: 200%; /* Twice the width for seamless animation */
  animation: scroll 20s linear infinite;
}

.Partner-Marquee-tag {
  flex: 0 0 auto;
  width: auto;
}

.Partner-Marquee-tag img {
  max-height: 150px;
  width: auto;
  display: block;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 640px) {
  .howitworkmobile img {
    width: 200px;
  }
  #support {
    padding: 10px !important;
  }
  .main_header {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 1536px) {
  .main_header {
    font-size: 40px;
  }
}
@media screen and (max-width: 700px) {
  .looper {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .howitworkmobile img {
    width: 250px;
  }
  .outlined-text {
    font-size: 80px !important;
  }
  #appdownload {
    width: 130px !important;
  }
}
#appdownload {
  width: 150px;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .outlined-text {
    font-size: 60px !important;
  }
}

.activeimage {
  scale: 1.2;
  transition: all 0.8s ease-in-out;
}

.img {
  opacity: 0.6;
  scale: 0.95;
  transition: all 0.8s ease-in-out;
}

.sideimage {
  scale: 1.05;
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.text-container {
  background-color: transparent;

  text-align: center;
}

.outlined-text {
  font-size: 100px;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px #e5daff40;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
}

.outlined-text::after {
  content: "REACH YOUR GOALS";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}
